// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// normalize CSS across browsers
import "./src/css/normalize.css"

import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";

// custom CSS styles
import "./src/css/style.css"
import "./src/css/general-css.css"

import React from 'react';

import MaintenanceChecker from './src/components/MaintenanceChecker'

export const wrapPageElement = ({ element }) => {
    return <MaintenanceChecker>{element}</MaintenanceChecker>;
};

console.log('browser');


export const shouldUpdateScroll = ({
    routerProps: { location },
    getSavedScrollPosition,
}) => {
    const { hash } = location;
    const currentPosition = getSavedScrollPosition(location);

    setTimeout(() => {
        if (hash) {
            const id = hash.substring(1); // Get id from hash, remove `#`
            const element = document.getElementById(id);

            if (element) {
                element.scrollIntoView();
            } else {
                window.scrollTo(...(currentPosition || [0, 0]));
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, 0);

    return false;
};


export const onInitialClientRender = () => {
    window.scrollTo(0, 0);
};

export const onRouteUpdate = () => {
    window.scrollTo(0, 0);
};