import React, { useEffect, useState } from 'react';
import { navigate } from 'gatsby';

const MaintenanceChecker = ({ children }) => {
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        console.log('maintenance check');
        // Ensure this code runs only in the browser
        if (typeof window !== "undefined") {
            fetch('https://backend.eksobionics.com/graphql', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    query: `
                        query {
                            generalSettings {
                                maintenanceMode
                            }
                        }
                    `,
                }),
            })
            .then(response => response.json())
            .then(data => {
                if (data.data.generalSettings.maintenanceMode) {
                    //console.log(data);                    
                    navigate('/maintenance');
                } else {
                    //console.log(data);
                    setIsLoading(false);
                }
            })
            .catch(error => {
                console.error('Error fetching maintenance mode status:', error);
                setIsLoading(false);
            });
        } else {
            // For SSR, we'll just assume maintenance mode is off
            setIsLoading(false);
        }
    }, []);

    

    return <>{children}</>;
};

export default MaintenanceChecker;
