exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-cookie-declaration-jsx": () => import("./../../../src/pages/cookie-declaration.jsx" /* webpackChunkName: "component---src-pages-cookie-declaration-jsx" */),
  "component---src-pages-maintenance-jsx": () => import("./../../../src/pages/maintenance.jsx" /* webpackChunkName: "component---src-pages-maintenance-jsx" */),
  "component---src-pages-new-accordian-jsx": () => import("./../../../src/pages/new-accordian.jsx" /* webpackChunkName: "component---src-pages-new-accordian-jsx" */),
  "component---src-pages-new-card-jsx": () => import("./../../../src/pages/new-card.jsx" /* webpackChunkName: "component---src-pages-new-card-jsx" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-homepage-template-js": () => import("./../../../src/templates/homepage-template.js" /* webpackChunkName: "component---src-templates-homepage-template-js" */),
  "component---src-templates-universal-template-js": () => import("./../../../src/templates/universal-template.js" /* webpackChunkName: "component---src-templates-universal-template-js" */)
}

